import React, { useState, useEffect, useRef } from "react"
import { graphql, navigate } from 'gatsby'
import WorkoutSeconds from './WorkoutSeconds'
import WorkoutWarmup from './WorkoutWarmup'
import WorkoutCooldown from './WorkoutCooldown'
import { Loading, Card, Input, Select, Spacer, Progress, Checkbox, Toggle } from '@geist-ui/react'
import { CheckCircle, Pause, Play, ChevronRight } from '@geist-ui/react-icons'
import Logo from '../../static/assets/CbCo.svg'


const WorkoutTimer = (props) => {

  const now = new Date()

  const [currentround, setCurrentRound] = useState(props.round)
  const [intervaltotal, setIntervalTotal] = useState(0)
  const [complete, setComplete] = useState()
  const [rest, setRest] = useState()
  const [work, setWork] = useState()
  const [interim, setInterim] = useState()
  const [selectedvideo, setSelectedVideo] = useState('work')
  const [burner, setBurner] = useState(props.workout.burner)

  const roundEl = document.getElementsByClassName("start-round")[0]
  const intervalEl = document.getElementsByClassName("end-interval")[0]

  useEffect(() => {
      if(props.round === 'Complete') {
        setComplete(true);
        setBurner();
      }
      if(props.round === 'Burner') {
        setBurner(true);
        setComplete(true);
      }
    }, [props.round]);

  useEffect(() => {
   document.getElementById("workout-container").classList.add("timing");
   return () => {
       document.getElementById("workout-container").classList.remove("timing");
    }
  }, []);
 

  useEffect(() => {
     document.getElementsByTagName("header")[0].style.display = "none";
     document.getElementById("back").style.display = "none";
      return () => {
       document.getElementsByTagName("header")[0].style.display = "flex";
       document.getElementById("back").style.display = "flex";
      }
  }, []);


  const changeVideo = (e) => {
    if(e === 30) {
      if(intervaltotal < 120) {
        setSelectedVideo('rest');
        setIntervalTotal(intervaltotal + 30);
      }
      else {
        if(currentround === (props.workout.rounds.length -1)) {
          setIntervalTotal(150);
          setComplete(true);
        }
        else {
          setSelectedVideo('interim');
          setIntervalTotal(150);
        }
      }
    }
    else if (e === 10) {
        if(props.showintervals) { intervalEl.play() };
        setSelectedVideo('work');
        setIntervalTotal(intervaltotal + 10);
    }
    else if (e === 2400) {
      setIntervalTotal(150);
      setComplete(true);
    }
    else {
        roundEl.play();
        setCurrentRound(currentround + 1);
        setSelectedVideo('work');
        setIntervalTotal(0);
    }
  }


  return (
    <div className="workout-timer">
    <img src={Logo} className="dark-logo" width="10%" height="auto" alt="Craft Boxing Co Logo" />
      
      {!complete && currentround === 'Warm Up' && <>
        <WorkoutWarmup onChange={() => setCurrentRound(0)} workout={props.workout} shortwarmup={props.shortwarmup} />
       <div className="start" onClick={() => setCurrentRound(0)}>Start Workout <ChevronRight color="#e5cab3" /></div>
      </>}

      {!complete && currentround !== 'Warm Up' && <>
        
        <h5>{props.strength ? 'Workout' : 'Round ' +  (currentround + 1)}</h5>
        {!props.showintervals && <Spacer y={2} />}
        <WorkoutSeconds timer={props.strength ? 2400 : (selectedvideo === 'work' ? 30 : selectedvideo === 'rest' ? 10 : 60)} 
         onChange={changeVideo} round={currentround} intervals={props.showintervals} strength={props.strength} />

        {(props.showintervals || selectedvideo === 'interim') && <h6>{selectedvideo === 'work' ? (intervaltotal < 30 ? 1 : intervaltotal === 40 ? 2 : intervaltotal === 80 ? 3 : 4) + ' of 4'  : 
        selectedvideo === 'interim' && ((((currentround + 1) % props.quotient === 0) && !props.showroad) || 
          (props.showroad && (currentround === 1 
                              || currentround === 3 
                              || currentround === 5
                              || currentround === 7
                              || currentround === 8
          ))) ? 
          <span className="rest">SWITCH</span> 
          : <span className="rest">REST</span>}</h6>}

        
        {selectedvideo !== 'interim' && <>

        {props.showcircuit && !props.showroad && <>
          <h3>
            {props.workout.headings.slice(0,6).map((heading, index) => (
                <div><span>{heading}</span><br />
                {props.strength ? props.workout.rounds[0].split(",").map((exercise) => <h4>{exercise}</h4>) : 
                props.workout.rounds[index * props.quotient + (currentround % props.quotient)]}
                </div>
              ))}
          </h3>
          {props.workout.headings.length > 6 && <h3 className="right">
            {props.workout.headings.slice(6,12).map((heading, index) => (
                <div><span>{heading}</span><br />
                 {props.workout.rounds[(index + 6) * props.quotient + (currentround % props.quotient)]}</div>
              ))}
          </h3>}</>}

          {props.showcircuit && props.showroad && 
            <h3>
              {currentround !== 'Complete' && props.circuitroadmode[currentround].map((round, index) => (
                  <div><span>{props.workout.headings[index]}</span><br />
                    {props.workout.rounds[round]}
                  </div>
                ))}
            </h3>
          }

          {!props.showcircuit && <h3 className="no-circuit">
              <span>{props.workout.headings[Math.floor((props.showroad ? props.roadmode[currentround] : currentround) / props.quotient)]}</span>
              <br />{props.workout.rounds[props.showroad ? props.roadmode[currentround] : currentround]}
            </h3>}

            </>}
        
         
         {selectedvideo === 'interim' && <>
           <div className="start" onClick={() => (roundEl.play(), setCurrentRound(currentround + 1), setSelectedVideo('work'), setIntervalTotal(0))}>
             Start Round {currentround + 2} <ChevronRight color="#e5cab3" />
            </div>
           {props.showcircuit && !props.showroad && <>
              <h3><div><span>UP NEXT</span></div>
               {props.workout.headings.slice(0,6).map((heading, index) => (
                  <div><span>{heading}</span><br />
                  {props.workout.rounds[index * props.quotient + ((currentround + 1) % props.quotient)]}</div>
                ))}</h3>
               {props.workout.headings.length > 6 && <h3 className="right">
                 {props.workout.headings.slice(6,12).map((heading, index) => (
                    <div><span>{heading}</span><br />
                    {props.workout.rounds[(index + 6) * props.quotient + ((currentround + 1) % props.quotient)]}</div>
                ))}</h3>}
             </>}

            {props.showcircuit && props.showroad && 
                <h3><div><span>UP NEXT</span></div>
                   {props.circuitroadmode[currentround + 1].map((round, index) => (
                      <div><span>{props.workout.headings[index]}</span><br />
                      {props.workout.rounds[round]}</div>
                    ))}
                 </h3>}

            {!props.showcircuit && <h3 className="no-circuit">
              <div><span>UP NEXT: {props.workout.headings[Math.floor((props.showroad ? props.roadmode[currentround + 1] : currentround + 1) / props.quotient)]}</span>
               <br />{props.workout.rounds[props.showroad ? props.roadmode[currentround + 1] : currentround + 1]}
              </div>
             </h3>}
          
          </>}

         <audio className="start-round">
            <source src="https://cdn.shopify.com/s/files/1/0512/4102/5695/files/boxing-bell.mp3?v=1636743841"></source>
         </audio>
         <audio className="end-interval">
            <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source>
         </audio>

      </>}
      {complete && burner &&
      <div className="centered">
        <h5>{props.workout.burner}</h5>
        <Spacer y={1.5} />
        <WorkoutCooldown burner={props.workout.burner} onChange={() => setBurner(false)} />
        <Spacer y={3} />
      </div>}
      {complete && !burner &&
      <div className="centered">
        <h5>{props.workout.cooldown ? props.workout.cooldown : 'Cool Down'}</h5>
        <Spacer y={1.5} />
        <WorkoutCooldown />
        <Spacer y={3} />
      </div>}
    </div>
  )
}

export default WorkoutTimer
