import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import HeadingBar from '../components/HeadingBar'
import Workout from '../components/Workout'
import RepeatTimer from '../components/RepeatTimer'
import { Loading, Card, Input, Button, Select, Spacer, Checkbox, Toggle, Tooltip } from '@geist-ui/react'
import { Search, Info, Edit, ChevronLeft, PlayCircle, Watch } from '@geist-ui/react-icons'

import Spotify from '../../static/assets/Spotify.png'
import Monday from '../../static/assets/Monday.png'
import Tuesday from '../../static/assets/Tuesday.png'
import Wednesday from '../../static/assets/Wednesday.png'
import Thursday from '../../static/assets/Thursday.png'
import Friday from '../../static/assets/Friday.png'
import Saturday from '../../static/assets/Saturday.png'
import Sunday from '../../static/assets/Sunday.png'

import { StaticImage } from 'gatsby-plugin-image'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

SwiperCore.use([Pagination]);

var today = new Date();
var dd = today.getDate();
var mm = today.getMonth()+1;
var yyyy = today.getFullYear();
if(dd<10){
  dd='0'+dd
} 
if(mm<10){
  mm='0'+mm
} 

today = yyyy+'-'+mm+'-'+dd;

 const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
 let currentday = weekdays[new Date().getDay()];
 const shortdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const TrainerWorkouts = ({data}) => {

   var days = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday'
  }

  var months = [
    'Jan 2023',
    'May 2023',
    'Jun 2023',
    'Jul 2023',
    'Aug 2023',
    'Sep 2023',
    'Oct 2023',
    'Nov 2023',
    'Dec 2023',
    'Jan 2024',
    'Nov 2023',
    'May 2023'
  ]

const [equipment, setEquipment] = useState([])
const [style, setStyle] = useState()
const [format, setFormat] = useState()
const [search, setSearch] = useState()
const [showworkout, setShowWorkout] = useState()
const [workout, setWorkout] = useState({})
const [month, setMonth] = useState(months[months.length - 1])
const [corporate, setCorporate] = useState()
const [day, setDay] = useState(currentday)
const [selectedslide, setSelectedSlide] = useState(shortdays.indexOf(currentday))
const [showtimer, setShowTimer] = useState()
const [monthindex, setMonthIndex] = useState(months.length - 1)

const workouts = data.allWorkout.edges

var currentmonth = months[months.length - 1].split(" ")[0];



const FilteredWorkouts = workouts.filter(function (e) {
    if(style) {
    return e.node.style === style;
    }
    else return e
  }).filter(function (e) {
    if(format) {
    return e.node.type === format;
    }
    else return e.node.type !== 'Personal Training'
  }).filter(function (e) {
    if(corporate === 'Calabasas') {
    return e.node.title.includes('||') || (e.node.location && e.node.location.includes('Calabasas'));
    }
    else if (corporate === 'Rock') {
      e.node.location && e.node.location.includes('Rock');
    }
    else return !e.node.title.includes('||') && (e.node.location ? e.node.location.includes('Box in Box') : true);
  }).filter(function (e) {
    if(equipment.length > 0) {
      return equipment.some((piece) => (e.node.equipment && e.node.equipment.includes(piece))) || (equipment.includes("None") && !e.node.equipment);
    }
    else return e
  }).filter(function (e) {
    if(search) {
    return e.node.title.toLowerCase().includes(search.toLowerCase());
    }
    else return e
  }).filter(function (e) {
    if(month) {
    return new Date(e.node.date).toUTCString().includes(month.split(" ")[0]) && new Date(e.node.date).toUTCString().includes(month.split(" ")[1]);
    }
    else return e
  }).filter(function (e) {
    if(day) {
    return new Date(e.node.date).toUTCString().includes(day);
    }
    else return e
  }).sort((a,b) => {
    if(new Date(a.node.date).toUTCString().includes(currentmonth) && new Date(b.node.date).toUTCString().includes(currentmonth)) {
      if(new Date(a.node.date) < new Date(b.node.date)) {
        return -1;
      }
      else {
        return 1;
      }
    }
    else if(new Date(a.node.date) < new Date(b.node.date)) {
      return 1;
    }
    else {
      return -1;
    }
  });

const equipmentHandler = val => setEquipment(val)
const styleHandler = val => setStyle(val)
const formatHandler = val => setFormat(val)

  return (
    <>
      <Helmet>
        <title>Trainer Workouts - Craft Boxing</title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <HeadingBar noimage="true" />
      {!showworkout && <div className="spotify-buttons home left">
          <Watch onClick={() => (setShowWorkout(true), setShowTimer(true))} />
      </div>}
      {!showworkout && <div className="day-swiper">
             <Swiper
                spaceBetween={20}
                breakpoints={{
                  1300: {
                    slidesPerView: 4,
                    slidesPerGroup: 1
                  },
                  900: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                  },
                  700: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                  }
                }}
                pagination={{clickable: true}}
                slidesPerView={1}
                initialSlide={selectedslide}
                onSlideChange={(e) => setDay(shortdays[e.activeIndex])}
              >
                <SwiperSlide onClick={() => (setDay('Mon'), setSelectedSlide(0), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Monday}
                        className={!day ? 'all' : day === 'Mon' ? 'selected' : 'not-selected'}
                        alt="Boxer Mondays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Tue'), setSelectedSlide(1), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Tuesday}
                        className={!day ? 'all' : day === 'Tue' ? 'selected' : 'not-selected'}
                        alt="Puncher Tuesdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Wed'), setSelectedSlide(2), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Wednesday}
                        className={!day ? 'all' : day === 'Wed' ? 'selected' : 'not-selected'}
                        alt="Boxer Puncher Wednesdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Thu'), setSelectedSlide(3), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Thursday}
                        className={!day ? 'all' : day === 'Thu' ? 'selected' : 'not-selected'}
                        alt="Counter Puncher Thursdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Fri'), setSelectedSlide(4), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Friday}
                        className={!day ? 'all' : day === 'Fri' ? 'selected' : 'not-selected'}
                        alt="Puncher Fridays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Sat'), setSelectedSlide(5), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Saturday}
                        className={!day ? 'all' : day === 'Sat' ? 'selected' : 'not-selected'}
                        alt="Brawler Saturdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Sun'), setSelectedSlide(6), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Sunday}
                        className={!day ? 'all' : day === 'Sun' ? 'selected' : 'not-selected'}
                        alt="Rhythm Sundays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
               </Swiper>
      </div>}
      <div className="workout-container" id="workout-container">
      {!showworkout && <div style={{padding: '0 5%'}}>
        <Spacer y={.5} />
        <div className="selects">
          <Select size="large" width="50%" placeholder="Class Type" value={format} onChange={formatHandler}>
              <Select.Option value="">All Types</Select.Option>
              <Select.Option value="Craft Boxing">Craft Boxing</Select.Option>
              <Select.Option value="Fundamentals">Fundamentals</Select.Option>
              <Select.Option value="Bagwork">Bagwork</Select.Option>
              <Select.Option value="Bodywork">Bodywork</Select.Option>
              <Select.Option value="Shadowboxing">Shadowboxing</Select.Option>
              <Select.Option value="Mittwork">Mittwork</Select.Option>
              <Select.Option value="Roadwork">Roadwork</Select.Option>
              <Select.Option value="Strength">Strength</Select.Option>
              <Select.Option value="Brawl">Brawl</Select.Option>
              <Select.Option value="Bagwork Plus">Bagwork Plus</Select.Option>
              <Select.Option value="Personal Training">Personal Training</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
            {/*<Select size="large" width="50%" placeholder="Style" value={style} onChange={styleHandler}>
              <Select.Option value="">Any Style</Select.Option>
              <Select.Option value="Boxer">Boxer</Select.Option>
              <Select.Option value="Puncher">Puncher</Select.Option>
              <Select.Option value="Brawler">Brawler</Select.Option>
              <Select.Option value="Counter Puncher">Counter Puncher</Select.Option>
              <Select.Option value="Boxer Puncher">Boxer Puncher</Select.Option>
              <Select.Option value="Sunday School">Sunday School</Select.Option>
            </Select>*/}
           <Select size="large" width="50%" clearable={true} value={month + "-" + monthindex} placeholder="Month" 
              onChange={val => (setMonth(val.split("-")[0]), setMonthIndex(val.split("-")[1]))}>
              <Select.Option value="">All Months</Select.Option>
              {months.map((mon, index) => (
                 <Select.Option value={mon + "-" + index}>Month {index + 1}</Select.Option>
              ))}
           </Select>
         </div>
         <Spacer y={.5} />
          <div id="workouts">
          {day && <>
            <Spacer y={1} />
            <div style={{width:'200px', margin: '0 auto 30px auto'}}>
              <Select placeholder="Select Location" width="100%" value={corporate} onChange={(val) => setCorporate(val)}>
                <Select.Option value="Calabasas">Calabasas</Select.Option>
                <Select.Option value="">Box in Box</Select.Option>
              </Select>
             </div>
            {FilteredWorkouts.length < 1 && <p className="empty centered">There are no workouts that match your search.</p>}
            {FilteredWorkouts.length > 0 && FilteredWorkouts.map((workout) => (
              <Card className={new Date(workout.node.date).toUTCString().includes(currentmonth) ? 'featured' : ''} onClick={() => (setWorkout(workout), setShowWorkout(true))}>
              <div className="flex">
                <span>{workout.node.type === 'Brawl' ? new Date(workout.node.date).toUTCString().split(" ")[2] + " " + new Date(workout.node.date).toUTCString().split(" ")[1]: 
                monthindex ? 'Month ' + (parseInt(monthindex) + 1) : months.includes(new Date(workout.node.date).toUTCString().split(" ")[2] + 
                  " " + new Date(workout.node.date).toUTCString().split(" ")[3]) ? 'Month ' + 
                (months.lastIndexOf(new Date(workout.node.date).toUTCString().split(" ")[2] + 
                  " " + new Date(workout.node.date).toUTCString().split(" ")[3]) + 1) : 
                 new Date(workout.node.date).toUTCString().split(" ")[2] + 
                  " " + new Date(workout.node.date).toUTCString().split(" ")[3]}</span>
                {workout.node.type === 'Other' || workout.node.type === 'Personal Training' ? 
                workout.node.type + " " + workout.node.title + " (" + workout.node.style + ")" :
                days[new Date(workout.node.date).toUTCString().split(", ")[0]] + ": " + workout.node.type}
              </div>
              <PlayCircle size={40} /></Card>
              ))}
           </>}</div>
      </div>}
      {showworkout && <>
        <div id="back" className="back" onClick={() => (setShowWorkout(), setWorkout(), setShowTimer())}><ChevronLeft size={20} /> Back to All</div>
        {!showtimer ? <Workout workout={workout.node} /> : <><Spacer y={2} /><RepeatTimer /></>}
      </>}
      </div>
    </>
  )
}

export default TrainerWorkouts

export const pageQuery = graphql`query workoutsQuery {
     allWorkout {
        edges {
          node {
            title
            description
            equipment
            _id
            _ts
            playlist
            rounds
            headings
            warmuprounds
            warmupheadings
            type
            style
            video
            date
            location
            burner
            cooldown
       }
     }
    }
  }
`
